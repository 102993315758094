<template>
  <div>
    <KTCard ref="preview" v-bind:title="title">
      <!--页面标题-->
      <template v-slot:title>
        <h3 class="card-title">
          <b-icon icon="blockquote-right" class="mx-3"></b-icon>
          角色编辑
        </h3>
      </template>

      <!--操作按钮组-->
      <template v-slot:toolbar>
        <div class="example-tools justify-content-center">
          <!--按钮组-->
          <b-button variant="success" @click="goBack()" class="mx-2">
            <i class="flaticon-reply"></i> 返回
          </b-button>
          <!--按钮组-->
        </div>
      </template>

      <!--数据表格-->
      <template v-slot:body>
        <div style="min-height: 70vh">
          <b-row>
            <b-col cols="1"></b-col>
            <b-col cols="8">
              <b-form
                id="roleForm"
                method="POST"
                class="form"
                novalidate="novalidate"
              >
                <b-row>
                  <b-col cols="3" class="text-right">
                    <label class="form-group-label">角色名称:</label>
                  </b-col>
                  <b-col cols="9">
                    <b-form-group>
                      <b-form-input
                        name="roleName"
                        type="text"
                        v-model="formModel.roleName"
                        placeholder="请输入角色名称..."
                      >
                      </b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="3" class="text-right">
                    <label class="form-group-label">角色编码:</label>
                  </b-col>
                  <b-col cols="9">
                    <b-form-group>
                      <b-form-input
                        name="roleCode"
                        type="text"
                        v-model="formModel.roleCode"
                        placeholder="请输入角色编码..."
                      >
                      </b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="3" class="text-right">
                    <label class="form-group-label">角色描述:</label>
                  </b-col>
                  <b-col cols="9">
                    <b-form-group>
                      <b-form-input
                        name="roleDesc"
                        type="text"
                        v-model="formModel.roleDesc"
                        placeholder="请输入角色描述..."
                      >
                      </b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="3" class="text-right">
                    <label class="form-group-label">角色权限:</label>
                  </b-col>
                  <b-col cols="9">
                    <v-treeview
                      :open-on-click="false"
                      :return-object="false"
                      :selectable="true"
                      :items="items"
                      :open-all="true"
                      color="danger"
                      item-key="treeId"
                      item-text="authorityName"
                      style="border: 1px solid #cccccc"
                      ref="authTree"
                      v-model="selected"
                    >
                      <template v-slot:prepend="{ item, open }">
                        <v-icon v-if="!item.iconClass" size="22">
                          mdi-folder
                        </v-icon>
                        <v-icon v-else size="22">
                          {{ item.iconClass }}
                        </v-icon>
                      </template>
                    </v-treeview>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="3" class="text-right">
                    <label>初始状态:</label>
                  </b-col>
                  <b-col cols="9">
                    <b-form-group>
                      <b-form-radio-group
                        size="lg"
                        name="status"
                        v-model="formModel.status"
                      >
                        <b-form-radio value="1">正常</b-form-radio>
                        <b-form-radio value="0">禁用</b-form-radio>
                      </b-form-radio-group>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="3"> </b-col>
                  <b-col cols="9">
                    <b-button v-on:click="onSubmit" variant="primary"
                      >保存</b-button
                    >
                    <b-button v-on:click="onReset" variant="danger" class="mx-5"
                      >重置</b-button
                    >
                  </b-col>
                </b-row>
              </b-form>
            </b-col>
            <b-col cols="3"></b-col>
          </b-row>
        </div>
      </template>
    </KTCard>
  </div>
</template>

<script>
import KTCard from "@/view/content/Card.vue";
import KTUtil from "@/assets/js/components/util";

import ApiService from "@/core/services/api.service";

// FormValidation plugins
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import Swal from "sweetalert2";

export default {
  name: "Edit",
  components: {
    KTCard,
  },

  props: {
    title: String,
  },

  data() {
    return {
      selected: [],
      formModel: {
        roleId: undefined,
        roleCode: "",
        roleName: "",
        roleDesc: "",
        status: 1,
        auths: [],
      },
      items: [
        {
          treeId: -1,
          authorityName: "管理平台",
          iconClass: "flaticon2-settings",
          children: [],
        },
        {
          treeId: -2,
          authorityName: "商户平台",
          iconClass: "flaticon-rotate",
          children: [],
        },
      ],
    };
  },

  mounted() {
    // Create a FormValidation instance
    let roleForm = KTUtil.getById("roleForm");
    this.fv = formValidation(roleForm, {
      fields: {
        roleName: {
          validators: {
            notEmpty: {
              message: "角色名称不能为空!",
            },
          },
        },
        roleCode: {
          validators: {
            notEmpty: {
              message: "角色编码不能为空!",
            },
          },
        },
        roleDesc: {
          validators: {
            notEmpty: {
              message: "角色描述不能为空!",
            },
          },
        },
        status: {
          validators: {
            notEmpty: {
              message: "初始状态不能为空!",
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        bootstrap: new Bootstrap(),
      },
    });
  },

  created() {
    this.formModel.roleId = this.$route.query.roleId;
    this.getData();
    this.getAuthTreeData();
  },

  methods: {
    goBack() {
      this.$router.push("/role/list");
    },

    getData() {
      let that = this;
      if (this.formModel.roleId != undefined) {
        //获取角色详情
        ApiService.query("/api/role/query_by_id", {
          params: {
            roleId: this.formModel.roleId,
          },
        }).then(({ data }) => {
          that.formModel = data.result;
        });
      }
    },

    getAuthTreeData() {
      let that = this;
      ApiService.get("/api/authority/query_auth_map").then(({ data }) => {
        let auths = data.result;
        let omsItems = that.genTreeItem(auths, -1);
        that.items[0].children = omsItems;
        let mchItems = that.genTreeItem(auths, -2);
        that.items[1].children = mchItems;

        that.$refs.authTree.updateAll(true);

        //获取角色权限列表
        if (this.formModel.roleId != undefined) {
          ApiService.query("/api/role/query_role_auths", {
            params: {
              roleId: this.formModel.roleId,
            },
          }).then(({ data }) => {
            let auths = data.result;
            that.formModel.auths = auths;
            auths.forEach(function (obj) {
              that.selected.push(obj.id);
            });
          });
        }
      });
    },

    genTreeItem(auths, ancestorId) {
      let items = new Array();
      let that = this;
      auths.forEach(function (obj) {
        if (ancestorId == null || obj.parentId == ancestorId) {
          let item = new Object();
          item.id = obj.id;
          item.authorityName = obj.authorityName;
          item.iconClass = obj.iconClass;
          item.treeId = obj.id;
          if (obj.subAuthority != null && obj.subAuthority != undefined) {
            item.children = that.genTreeItem(obj.subAuthority, null);
          }
          items.push(item);
        }
      });
      return items;
    },

    onReset() {
      this.$refs["my-modal"].hide();
    },

    onSubmit() {
      let that = this;
      let submitUrl =
        this.formModel.roleId == undefined
          ? "/api/role/create"
          : "/api/role/update";
      this.fv.validate().then(function (status) {
        if (status === "Valid") {
          ApiService.post(submitUrl, that.formModel).then(({ data }) => {
            if (data.success) {
              Swal.fire({
                title: "",
                text: "保存成功！",
                icon: "success",
                confirmButtonClass: "btn btn-success",
                heightAuto: false,
                timer: 1500,
              }).then(function (result) {
                if (result.value || result.dismiss === "timer") {
                  that.$router.push("/role/list");
                }
              });
            } else {
              Swal.fire({
                title: "保存失败！",
                text: data.message,
                icon: "warning",
                confirmButtonClass: "btn btn-success",
                heightAuto: false,
                timer: 3000,
              });
            }
          });
        }
      });
    },
  },
  computed: {},
  watch: {
    selected: {
      handler(newVal, oldVal) {
        if (newVal != null && newVal != undefined && newVal.length > 0) {
          let auths = this.formModel.auths;
          auths.splice(0, auths.length);
          newVal.forEach(function (item) {
            auths.push({
              authId: item,
            });
          });
          this.formModel.auths = auths;
        }
      },
      deep: true,
    },
  },
};
</script>